@tailwind base;
@tailwind components;
@tailwind utilities;




.scrollbar-thin {
    scrollbar-width: thin;
    scrollbar-color: rgba(#888, .5) rgba(#fff, 0);
}
.scrollbar-thin::-webkit-scrollbar {
    width: 5px;
}
.scrollbar-thin::-webkit-scrollbar-track {
    background: rgba(#fff, 0);
}
.scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: rgba(#888, 0);
    border-radius: 10px;
    border: 1px solid #fff;
}
.scrollbar-thin::-webkit-scrollbar-thumb:hover {
    background: rgba(#888, .5); 
}

.direction-rtl {
    direction: rtl;
}
  
.direction-ltr {
    direction: ltr;
}





.animate-pop-swirl {
    animation: pop-swirl 300ms ease forwards;
}

@keyframes pop-swirl {
    0% {
      transform: scale(.2) rotate(-12deg) translate(-25rem, -10rem);
      opacity: 0;
    }
    100% {
      transform: scale(1) rotate(0deg) translate(0, 0);
      opacity: 1;
    }
}


.animate-fade-in {
    animation: fade-in 300ms ease-in-out;
}
@keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

.animate-progress {
    animation: progress 3000ms ease-in-out;
}
@keyframes progress {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
}
.animate-progress-100-0 {
    animation: progress 3000ms ease-in-out reverse;
}


@keyframes animate-scrollbar {
    0% {
      left:0%;
      right:100%;
      width:0%;
    }
    10% {
      left:0%;
      right:75%;
      width:25%;
    }
    90% {
      right:0%;
      left:75%;
      width:25%;
    }
    100% {
      left:100%;
      right:0%;
      width:0%;
    }
}



.loader {
    position: relative;
    width: 85px;
    height: 50px;
    background-repeat: no-repeat;
    background-image: linear-gradient(#FFF 50px, transparent 0),
                      linear-gradient(#FFF 50px, transparent 0),
                      linear-gradient(#FFF 50px, transparent 0),
                      linear-gradient(#FFF 50px, transparent 0),
                      linear-gradient(#FFF 50px, transparent 0),
                      linear-gradient(#FFF 50px, transparent 0);
    background-position: 0px center, 15px center, 30px center, 45px center, 60px center, 75px center, 90px center;
    animation: rikSpikeRoll 0.65s linear infinite alternate;
  }
@keyframes rikSpikeRoll {
  0% { background-size: 10px 3px;}
  16% { background-size: 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px, 10px 3px}
  33% { background-size: 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px}
  50% { background-size: 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px}
  66% { background-size: 10px 3px, 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px}
  83% { background-size: 10px 3px, 10px 3px,  10px 10px, 10px 30px, 10px 50px, 10px 3px}
  100% { background-size: 10px 3px, 10px 3px, 10px 3px,  10px 10px, 10px 30px, 10px 50px}
}